// @flow

import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import styled, { css } from 'react-emotion'
import Helmet from 'react-helmet'
import { ThemeProvider } from 'emotion-theming'
import { rem } from 'polished'
import Layout from '../components/Layout'
import theme, { media } from '../styles/theme'
import Hero from '../components/Hero'
import Button from '../components/Button'
import LayoutContainer, {
  LayoutContainerStyle,
  SplitLayoutContainer,
  SplitLayoutContainerStyle,
} from '../components/LayoutContainer'
import Lead from '../components/Lead'
import BlockLink, { BlockLinks } from '../components/BlockLink'
import HeroContent from '../components/HeroContent'

const HomeHeroSubheader = styled.p`
  color: #fff;
  margin-top: 30px;
  margin-bottom: 0;
  font-size: ${rem('16px')};
  font-weight: 800;
  text-shadow: 0 0 40px rgba(0, 0, 0, 0.25);
  text-align: center;
  letter-spacing: ${rem('0.6px')};

  ${media.greaterThan('small')`
    font-size: ${rem('24px')};
  `};
`

const HomeHeroType = styled.div`
  color: #fff;
  margin: 0 0 30px;
  font-size: ${rem('48px')};
  line-height: 1.2;
  font-weight: 800;
  text-shadow: 0 0 40px rgba(0, 0, 0, 0.25);
  text-align: center;

  ${media.greaterThan('small')`
    font-size: ${rem('72px')};
    font-weight: 900;
    text-align: left;
  `};

  p {
    margin: 0;
  }
`

const HomeHeroContent = styled(HeroContent)`
  align-items: center;

  ${media.greaterThan('small')`
    align-items: flex-start;
  `};
`

const MapContainer = styled.div`
  width: calc(100% + 40px);
  padding-bottom: 100%;
  height: 0;
  position: relative;
  margin-left: -20px;

  ${media.greaterThan('medium')`
    margin-left: 0;
    width: 100%;
  `};
`

const Map = styled.iframe`
  border: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const MapImageStyles = css`
  display: none;
  position: absolute;
  z-index: 3;
  width: 200px;
  height: 150px;
  object-fit: contain;
  pointer-events: none;

  ${media.greaterThan('medium')`
    display: block;
  `};
`

const MapImage1 = styled.img`
  ${MapImageStyles};
  left: -40px;
  top: 0;
  transform: translateY(-50%);
  object-position: left top;
`

const MapImage2 = styled.img`
  ${MapImageStyles};
  right: 20px;
  bottom: 0;
  transform: translateY(30%);
  object-position: right bottom;
`

type HomepageProps = {
  pageContext: Object,
  data: Object,
}

export default ({ pageContext, data }: HomepageProps) => {
  const {
    heroSubheader,
    heroText,
    heroCtaLabel,
    heroCtaLink,
    heroImage,
    introduction,
    promos,
    embedCode,
    visitHeader,
    visitDescription,
    visitContactAreaOne,
    visitContactAreaTwo,
  } = data.homepage.data
  const { site } = data

  return (
    <Layout>
      <Helmet>
        <title>
          Pediatric Destistry in Manoa Valley - {site.siteMetadata.title}
        </title>
      </Helmet>
      <Hero {...pageContext.heroProps} src={heroImage.url} alt={heroText.text}>
        <HomeHeroContent>
          <HomeHeroSubheader>{heroSubheader}</HomeHeroSubheader>
          <HomeHeroType dangerouslySetInnerHTML={{ __html: heroText.html }} />
          <Button to={heroCtaLink.url}>{heroCtaLabel}</Button>
        </HomeHeroContent>
      </Hero>
      <LayoutContainer>
        <Lead dangerouslySetInnerHTML={{ __html: introduction.html }} />
        {promos.length >= 1 && (
          <BlockLinks>
            {promos.map(promo => (
              <BlockLink
                key={promo.title}
                to={promo.link.url}
                src={promo.image.url}
                alt={promo.title}
                title={promo.title}
                description={promo.description}
              />
            ))}
          </BlockLinks>
        )}
      </LayoutContainer>
      <SplitLayoutContainer
        center={true}
        className={css`
          ${media.greaterThan('small')`
            margin-top: 50px;
            margin-bottom: 50px;
          `};
        `}>
        <MapContainer>
          <Map
            title="google-map"
            src={embedCode.text}
            frameborder="0"
            allowfullscreen
          />
        </MapContainer>
        <div>
          <h3>{visitHeader}</h3>
          <p>{visitDescription}</p>
          <SplitLayoutContainer
            className={css`
              margin-bottom: 50px;

              ${media.greaterThan('small')`
                margin-bottom: 100px;
              `};
            `}>
            <div
              dangerouslySetInnerHTML={{ __html: visitContactAreaOne.html }}
            />
            <div
              dangerouslySetInnerHTML={{ __html: visitContactAreaTwo.html }}
            />
          </SplitLayoutContainer>
        </div>
      </SplitLayoutContainer>
    </Layout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    homepage: prismicHome {
      data {
        heroSubheader: hero_subheader_text
        heroText: hero_text {
          html
          text
        }
        heroCtaLabel: hero_cta_label
        heroCtaLink: hero_cta_link {
          url
        }
        heroImage: hero_image {
          url
          dimensions {
            width
            height
          }
        }
        introduction {
          html
        }
        promos {
          title: promo_title
          description: promo_description
          link: promo_link {
            url
          }
          image: promo_image {
            url
            dimensions {
              width
              height
            }
          }
        }
        embedCode: embed_code {
          text
        }
        visitHeader: visit_header
        visitDescription: visit_description
        visitContactAreaOne: visit_contact_area_1 {
          html
        }
        visitContactAreaTwo: visit_contact_area_2 {
          html
        }
      }
    }
  }
`

import React from 'react'
import styled, { css } from 'react-emotion'
import { rem } from 'polished'
import theme, { media } from '../styles/theme'
import Link from './Link'

export const BlockLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;

  ${media.greaterThan('small')`
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  `};
`

const BlockLinkStyles = css`
  display: block;
  margin-bottom: 30px;

  ${media.greaterThan('small')`
    width: 33.33%;
    margin-right: 30px;
    margin-bottom: 0;
  `};

  &:last-of-type {
    margin-right: 0;
  }
`

export const BlockLinkImageContainer = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 60%;
  margin-bottom: 15px;

  ${media.greaterThan('small')`
    margin-bottom: 35px;
  `};
`

export const BlockLinkImage = styled.img`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const BlockLinkTitle = styled.p`
  color: ${theme.colors.bodyCopy};
  font-size: ${rem('24px')};
  line-height: 1;
  font-weight: bold;
  text-align: center;
  margin: 0;

  ${media.greaterThan('small')`
    line-height: 2;
    margin: 1rem 0;
  `};
`

export const BlockLinkDescription = styled.p`
  color: ${theme.colors.secondaryCopy};
  line-height: 1.5;
  text-align: center;
  margin: 10px 0;
`

export const BlockLink = ({ to, src, alt, title, description }) => (
  <Link
    to={to}
    className={css`
      ${BlockLinkStyles};
    `}>
    <BlockLinkImageContainer>
      <BlockLinkImage src={src} alt={alt} />
    </BlockLinkImageContainer>
    <BlockLinkTitle>{title}</BlockLinkTitle>
    <BlockLinkDescription>{description}</BlockLinkDescription>
  </Link>
)

export default BlockLink

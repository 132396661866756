import React from 'react'
import styled, { css } from 'react-emotion'
import { rem } from 'polished'
import { media } from '../styles/theme'

const HeroStyles = css`
  width: 100vw;
  position: relative;
  z-index: 1;
  padding-top: 175px;
  padding-bottom: 75px;

  ${media.greaterThan('small')`
    height: 620px;
    padding-top: 100px;
    padding-bottom: 0;
  `};

  &:after {
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`

export const HeroImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
`

export const Hero = ({ children, src, alt, topOverlay, fullOverlay }) => (
  <div
    className={css`
      ${HeroStyles};
      &:after {
        ${fullOverlay &&
          css`
            background-color: rgba(32, 26, 22, 0.4);
          `} ${topOverlay &&
          css`
            background: linear-gradient(
              to bottom,
              rgba(64, 112, 128, 0.68) 0%,
              rgba(64, 112, 128, 0) 100px
            );
          `};
      }
    `}>
    <HeroImage src={src} alt={alt} />
    {children}
  </div>
)

export default Hero
